import { headerItem } from '@/utils/table'
import { formatNumber, date } from '@/utils/string/index.js'

export default {
  stableCoinsHeader,
  liquidationHeader,
}

function stableCoinsHeader () {
  return [
    headerItem('Symbol', 'symbol', {
      cellClass: 'font-bold sticky left-0',
      generator: (item) => {
        return { value: item.symbol }
      },
    }),
    headerItem('Amount (USD)', 'amount', {
      generator: (item) => {
        return {
          value: formatNumber(item.amount),
        }
      },
    }),
    headerItem('BTC Price', 'price', {
      generator: (item) => {
        return {
          value: formatNumber(item.price),
        }
      },
    }),
    headerItem('Date', 'date', {
      generator: (item) => {
        return {
          value: date(item.timestamp),
        }
      },
    }),
  ]
}

// Exchange	Volume (USD)	Side	Price	Date
function liquidationHeader () {
  return [
    headerItem('Exchange', 'exchange', {
      cellClass: 'font-bold sticky left-0',
      generator: (item) => {
        return { value: item.exchange }
      },
    }),
    headerItem('Volume (USD)', 'volume', {
      generator: (item) => {
        return { value: formatNumber(item.volume) }
      },
    }),
    headerItem('Side', 'side', {
      cellClass: 'font-bold capitalize',
      generator: (item) => {
        return {
          value: item.side,
        }
      },
    }),
    headerItem('BTC Price', 'price', {
      generator: (item) => {
        return { value: formatNumber(item.price) }
      },
    }),
    headerItem('Date', 'date', {
      generator: (item) => {
        return { value: date(item.timestamp) }
      },
    }),
  ]
}
