import DInput from '@/components/daisy/atoms/input/index.vue'

export function header(title, key, _this) {
  return {
    title,
    key,
    generator: (item) => {
      return {
        component: DInput,
        props: {
          value: item[key],
        },
        events: {
          onChange: (value) => {
            _this.setValue(item.index, key, value)
          },
        },
      }
    },
  }
}

export function headerItem(title, key, ext) {
  return {
    title,
    key,
    ...ext,
  }
}
