<template>
  <div class="flex flex-col md:flex-row md:gap-4">
    <div class="w-full md:w-1/2 h-screen">
      <div class="font-bold">{{ $t('Mint history of stable coins') }}</div>
      <d-table
        :headers="stableCoinsHeader"
        :items="stableCoins"
        wrapperClasses="h-full overflow-y-scroll"
        class="mt-4"
      />
    </div>

    <div class="w-full md:w-1/2 h-screen">
      <div class="font-bold">{{ $t('Liquidation') }}</div>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <span>Exchange:</span>
          <d-select :items="exchanges" v-model="exchange" />
        </div>
        <div>
          <span>Side:</span>
          <d-select :items="sides" v-model="side" />
        </div>
        <div>
          <span>Range:</span>
          <d-select :items="ranges" v-model="range" />
        </div>
      </div>
      <d-table
        :headers="liquidationHeader"
        :items="losscuts"
        class="mt-4"
        wrapperClasses="h-full overflow-y-scroll"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import headersGenerator from './headers.js'
const exchanges = ['ALL', 'BYBIT', 'BINANCE', 'OKEX'].map((exchange) => ({
  label: exchange.toUpperCase(),
  value: exchange,
}))

const sides = ['ALL', 'BUY', 'SELL'].map((side) => ({
  label: side.toUpperCase(),
  value: side,
}))

const ranges = [
  { label: 'ALL', value: 0 },
  { label: '≧100,000', value: 100000 },
  { label: '≧300,000', value: 300000 },
  { label: '≧500,000', value: 500000 },
  { label: '≧1,000,000', value: 1000000 },
]

export default {
  name: 'collections',

  meta: {
    title: 'Collections',
  },

  data: () => ({
    exchange: 'ALL',
    side: 'ALL',
    range: 0,
    exchanges,
    sides,
    ranges,
    stableCoinsHeader: headersGenerator.stableCoinsHeader(),
    liquidationHeader: headersGenerator.liquidationHeader(),
  }),

  async created() {
    this.$store.dispatch('collections/load')
  },

  computed: {
    ...mapState({
      liquidations: (state) => state.collections.liquidations,
      stableCoins: (state) => state.collections.stableCoins,
    }),
    losscuts() {
      return this.liquidations.filter((el) => {
        if (this.exchange !== 'ALL') {
          if (el.exchange !== this.exchange) {
            return false
          }
        }
        if (this.side !== 'ALL') {
          if (el.side !== this.side) {
            return false
          }
        }
        return el.volume > this.range
      })
    },
  },
}
</script>
